<template>
<div>
  <a-modal
    class="ocr-tips-modal"
    :width="460"
    title="提示"
    :visible="visible"
    :maskClosable="false"
    @cancel="handleClose"
  >
    <template slot="footer">
      <div  class="button">
        <div @click="handleClose" class="cancle">取消</div>
        <div @click="handleOk" class="ok">确定</div>
      </div>
    </template>
   
    <div class="modal-content">
      <p>请选择版面类型：</p>
      <div class="content">
        <div
          v-for="item in listArr"
          :key="item.id"
          :class="[{'active': item.id == value}]"
          @click="handleChangeType(item.id)"
        >
          <img :src="item.imgUrl" />
          <p>{{ item.text }}</p>
        </div>
      </div>
    </div>
  </a-modal>
</div>
  
</template>

<script>
export default {
  props: [
    'visible',
    'handleClose',
    'handleCallback',
  ],
  data() {
    return {
      /** 单选框 */
      listArr: [
        {
          id: 1,
          imgUrl: require('@/assets/layoutType1.jpg'),
          text: '上下通栏'
        },
        {
          id: 2,
          imgUrl: require('@/assets/layoutType2.png'),
          text: '上下两栏'
        },
        {
          id: 3,
          imgUrl: require('@/assets/layoutType3.png'),
          text: '上中下三栏'
        }
      ],
      value: 1,
    }
  },
  methods: {
    /** 提交 */
    handleOk() {
      this.handleCallback(this.value)
    },
    handleChangeType(data) {
      this.value = data
    }
  }
}
</script>

<style lang="less">
.ocr-tips-modal {
  .ant-modal-content {
    .ant-modal-header {
      border:  none;
      .ant-modal-title {
        width: 80px;
        height: 32px;
        border-bottom: 5px solid #6868CE;
        display: flex;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        color: #6868CE;
      }
    }

    .ant-modal-footer {
      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 46px;

        
        div {
          width: 84px;
          height: 34px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-weight: 500;
          color: #9B9B9B;
          cursor: pointer;
        }

        .cancle {
          border: 1px solid #9B9B9B;
          margin-right: 50px;
        }


        .ok {
          background: url(../assets/beijing.png) no-repeat;
          background-size: cover;
          color: #fff;
          border: none;
        }

        div {
          &:nth-of-type(1):hover {
            border: 1px solid #6868CE;
            color: #6868CE;
          }
          &:nth-of-type(2):hover {
            opacity: 0.9;
          }
        }
      }
    }

    .modal-content {
      width: 100%;
      min-height: 160px;
      max-height: 300px;
      overflow-y: auto;

      .content {
        width: 100%;
        height: 100%;
        display: flex;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 28px;
        margin-top: 20px;
        & > div {
          flex: 1 0 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        img {
          width: 120px;
          height: 150px;
          cursor: pointer;
        }
        .active {
          img {
            border: 1px solid #6868CE;
          }
        }
      }
    }
  }
}

</style>