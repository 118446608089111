<template>
  <div class="ocr-image-box">
    <div class="nav-title">
      <span><i></i>首页  —  OCR识别 — 《{{bookName}}》</span>
    </div>
    <div class="content">
      <div class="title">
        <span class="active">《{{bookName}}》 </span>
        <div style="display: flex; align-items: center;">
          <div class="status-box" >OCR识别状态
            <a-radio-group v-model="ocrStatus" name="radioGroup" @change="handleChangeStatus">
              <a-radio 
                v-for="item in statusOptions"
                :key="item.id"
                :value="item.id"
              >
                {{ item.text }}
              </a-radio>
            </a-radio-group>
          </div>
          <div class="input">
            <input type="text" v-model="inputValue" placeholder="输入文件名称" @keyup.enter="handleSearch">
            <img src="@/assets/fangdajing.png" alt="" @click="handleSearch">
          </div>
          <b class="button-primary" @click="onBack" style="font-style: normal;">返回</b>
        </div>
      </div>
      <div class="list">
        <div class="top">
          <div class="left">
            <span style="margin-left: 0;" class="button-disabled" v-if="ocrStatusStep == 2 || loading">上传文件</span>
            <div v-else class="addBook">
              上传文件
              <!-- accept="image/png,image/jpeg,image/tiff,image/tif" -->
              <input
                type="file"
                multiple="multiple"
                accept="image/png,image/jpeg,image/tiff,image/tif,application/pdf,.djvu"
                id="files"
                @change="handleChange"
              />
            </div>
            <span class="tips" @click="handleTipsShow"><a-icon type="question-circle" />默认依据图像名称排序</span>
          </div>
          <div class="right">
            <span class="sort-box" v-if="ocrStatusStep != 2">顺序移动至：
              <input type="text" @keyup.enter="onMove" v-model="sortNum" />
            </span>
            <span class="button-primary" @click="handleAll">全选</span>
            <span class="button-primary" @click="handleCancleAll">取消全选</span>
            <span class="button-disabled" v-if="ocrStatusStep == 2">开始识别</span>
            <span class="button-search" @click="handleOcrLayoutType" v-else>开始识别</span>
            <span class="button-disabled" v-if="ocrStatusStep == 2">删除图片</span>
            <span class="button-cancle" v-else @click="handleDeleteClose('pic')">删除图片</span>
            <span class="button-cancle" @click="handleDeleteClose('book')">删除图书</span>
          </div>
        </div>

        <a-spin :spinning="loading || countLoading" :tip="countLoading ? '页数计算中，请等待' : '上传中，请稍候'">
          <div class="bottom">
            <div
              class="img-box"
              v-for="(item, index) in bookList"
              :key="item.id"
              @click.stop="imgCompare(item.id, item.ocrImgId, item.ocrStatus)"
              :id="item.id"
            >
              <span 
                :class="['checked', {active: item.checked}]"
                @click.stop="handleSelect(item.id)"
              ></span>
              <div class="ocr-img-wrap">
                <div class="ocr-img-box" v-if="item.ocrImgId">
                  <span>{{index + 1}}</span>
                    <img
                      :src="`${imgPath}${item.compressFilePath}`"
                    />
                </div>
                <div v-else class="ocr-img-box" @click.stop="show(index)">
                  <span>{{index + 1}}</span>
                    <img
                      class="previewer-demo-img"
                      :src="`${imgPath}${item.compressFilePath}`"
                    />
                </div>
                <div v-if="item.ocrStatus == 1" class="fileName-box">
                  <p :title="item.fileName">
                  <img src="@/assets/icon-success.png" v-if="item.ocrStatus == 1"/>
                  {{ item.fileName }}
                  </p>
                </div>
                <div v-else-if="item.ocrStatus == 2" class="fileName-box error">
                  <p :title="item.fileName">
                    <img src="@/assets/icon-error.png" v-if="item.ocrStatus == 2"/>
                    {{ item.fileName }}
                  </p>
                  <p :title="item.ocrErrorMsg">失败原因：{{item.ocrErrorMsg}}</p>
                </div>
                <div v-else class="fileName-box">
                  <p :title="item.fileName">
                    {{ item.fileName }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </a-spin>
        <Previewer ref="previewer" :list="previewerList" :options="options" />
      </div>
    </div>
    <!-- 删除弹窗 -->
    <HintOrDownModal
      v-if="!loading && deleteVisible"
      :visible="deleteVisible"
      :tipsType="deleteType == 'pic' ? 'tipsOne' : 'tipsTwo'"
      :handleClose="handleDeleteClose"
      :handleCallback="handleDeleteCallBack"
      :picNum="picNum"
      :boxNum="1"
      :textNum="textNum"
    />

    <!-- ocr弹窗 -->
    <HintOrDownModal
      v-if="ocrVisible"
      :visible="ocrVisible"
      :handleClose="handleOcrClose"
      :handleCallback="handleOcrCallBack"
      tipsType="tipsNine"
      :percentNum="+percentNum"
      :steps="true"
      :picNum="picNum"
      :layoutTypeText="layoutType == 1 ? '上下通栏' : layoutType == 2 ? '上下两栏' : layoutType == 3 ? '上中下三栏' : ''"
      :remainingData="remainingData"
    />

    <!-- 上传图片首次提示 -->
    <HintOrDownModal
      v-if="tipsVisible"
      :visible="tipsVisible"
      :handleClose="() => tipsVisible= false"
      :handleCallback="() => tipsVisible= false"
      tipsType="tipsFifteen"
      buttonText="我知道了"
    />

    <!-- 图片根据后台设置的限制判断 -->
    <TipsModal
      v-if="imgTipsVisible"
      :visible="imgTipsVisible"
      tipsType="tipsFour"
      buttonText="我知道了"
      :handleClose="tipsImageCallBack"
      :limitImageObj="limitImageObj"
    />

    <!-- 上传失败弹窗提示 -->
    <UploadModal
      v-if="upFailVisible"
      :visible='upFailVisible'
      :handleClose='closeUploadModal'
      :exceedFiveList='exceedFiveList'
      :jamList='jamList'
      :type='upFailCondition'
      :fileFailCount="fileFailCount"
    />

    <!-- 额度不够提示 -->
    <HintOcrImgModal
      :visible="remainingFlag"
      :handleClose="remainingImageCallBack"
      :handleCallback="remainingImageOk"
      :layoutTypeText="layoutType == 1 ? '上下通栏' : layoutType == 2 ? '上下两栏' : layoutType == 3 ? '上中下三栏' : ''"
      :ocrLimitObj="remainingData"
    />

    <!-- 额度不够充值成功提示 -->
    <TipsModal
      v-if="rechargeOkFlag"
      :visible="rechargeOkFlag"
      tipsType="tipsSix"
      buttonText="我知道了"
      :handleClose="rechargeOkCallBack"
      :num="rechargeOkNum"
    />

    <!-- <Recharge
      v-if="rechargeFlag"
      @cancle="changeCancle"
      :pageData='{ [remainingData.price]: remainingData.needAvailableCount}'
    /> -->

    <!-- 选择ocr识别版式 -->
    <OcrLayoutTypeModal
      v-if="layoutTypeFlag"
      :visible="layoutTypeFlag"
      :handleClose="handleLayoutTypeClose"
      :handleCallback="handleLayoutTypeOk"
    />
  </div>
</template>

<script>
import axios from 'axios';
import { message } from 'ant-design-vue';
import Cookies from 'js-cookie'
// import draggable from 'vuedraggable';
import HintOrDownModal from '@/components/HintOrDownModal.vue';
import { getToken } from '@/utils/auth';
import vuePicturePreview from 'vue-picture-preview';
import {
  getImglist , ocr, deleteImgOcr, doSort, deleteBook,
  getOcrProgress, getDelMsg, metabookProgress, beforeCheck,
  getOcrCheckList, getLimitImageBook
} from '@/api/ocr';
import TipsModal from '@/components/TipsModal.vue';
import UploadModal from '@/components/UploadModal.vue';
import Recharge from '@/views/recharge/index.vue';
import OcrLayoutTypeModal from '@/components/OcrLayoutTypeModal.vue';
import { consumeCheck } from '@/api/institution'
import HintOcrImgModal from '@/components/HintOcrImgModal.vue';

export default {
  components: { 
    // draggable,
    HintOrDownModal,
    Previewer: vuePicturePreview,
    TipsModal,
    UploadModal,
    Recharge,
    OcrLayoutTypeModal,
    HintOcrImgModal
  },
  data() {
    return {
      /** input Search */
      inputValue: '',
      pageSize: 1000000,
      /** 图书列表 */
      bookList: [],
      /** 图书id */
      bookId: '',
      bookName: '',
      imgPath: process.env.VUE_APP_IMG_PATH,
      uploadURL: '',
      deleteVisible: false,
      statusOptions: [
        {
          id: 0,
          text: '未识别'
        },
        {
          id: 1,
          text: '已识别'
        },
        {
          id: 2,
          text: '识别异常'
        }
      ],
      ocrStatus: '',
      deleteType: '',
      loading: true,
      loadingOcr: false,
      countLoading: false, // 页数计算
      ocrVisible: false,
      options: {
        getThumbBoundsFn(index) {
          let thumbnail = document.querySelectorAll('.previewer-demo-img')[
            index
          ];
        }
      }, // 图片放大配置
      previewerList: [], // 缩略图数组
      tipsStep: 'one',
      percentNum: 0,
      picNum: 0,
      textNum: 0,
      ocrStatusStep: 0,
      sortNum: '', // 移动顺序
      tipsVisible: false,
      imgTipsVisible: false, // 图片超出提示
      upFailVisible: false,
      upFailCondition: 'all', // all 全部 a 情况a b 情况b
      // 超过5兆照片集合
      exceedFiveList: [],
      //拥堵集合
      jamList: [],
      remainingFlag: false,
      remainingData: {},
      // rechargeFlag: false,
      rechargeOkFlag: false,
      rechargeOkNum: 0,
      layoutTypeFlag: false,
      layoutType: 1,
      userInfo: {},
      limitImageObj: {}
    };
  },
  created() {
    this.userInfo = JSON.parse(Cookies.get('userInfo'))
    this.bookId = this.$route.query.id
    this.bookName = this.$route.query.bookName
    this.ocrStatusStep = this.$route.query.ocrStatus
    this.handleOcrList()
    this.uploadURL = process.env.VUE_APP_BASE_API + `ocr/uploadImgs/${this.bookId}`

    document.addEventListener("visibilitychange", this.handleVisiable);
  },
  destroyed() {  
    document.removeEventListener('visibilitychange', this.handleVisiable)  
  },
  methods: {
    handleVisiable(e) {  
      switch(e.target.visibilityState) {
        case 'prerender':
          console.log('网页预渲染，内容不可见')
          break;
        case 'hidden':
          console.log('内容不可见，处理后台、最小化、锁屏状态')
          break;
        case 'visible':
          // 图片上传中不进行刷新操作
          if (this.loading) return
          this.handleOcrList(this.bookList)
          break;
      }
    },
    remainingImageOk(type) {
      this.remainingFlag = false
      console.log('this.remainingFlag', this.remainingFlag)
      if (type == 'ok') {
        this.ocrVisible = true
        this.handleOcrCallBack()
      }
    },
    show(index) {
      // 显示特定index的图片，使用ref
      this.$refs.previewer.show(index);
      this.$refs.previewer.style = 'border: 1px solid red;'
    },
    // 图片拖拽移动
    async onMove() {
      let numReg = /^[0-9]+$/g
      if (!numReg.test(this.sortNum)) {
        return this.$message.error('请输入正确的移动位置');
      }
      let imgIdList = this.bookList.map(item => {
        if (item.checked){
          return item.id
        }
      }).filter(item => item)

      if (!this.sortNum) {
        return this.$message.warn('请先输入要移动的位置');
      }

      if (!imgIdList.length) {
        return this.$message.warn('请先选择要移动的图片');
      }

      try {
        await doSort({
          id: imgIdList.join(','),
          sort: this.sortNum
        })
        this.$message.success('图片位置移动成功');
        this.sortNum = ''
        this.handleOcrList() 
      } catch (error) {
        // this.$message.error(error.msg);
      }
     
    },  
    // 上传图片 
    async handleChange(info) {
      this.loading = true;
      let formData = new FormData()
      let filesList = Object.values(info.target.files)

      const res = await getLimitImageBook({
        bookId: this.bookId,
      })

      if (res.data.isBookLimit) {
        let num = 0
        filesList.map(item => {
          num = num + item.size
        })
        if (num > res.data.thisBookMaxLimitToB) {
          this.limitImageObj = res.data
          this.imgTipsVisible = true
          this.loading = false;
          document.querySelector('#files').value = null
          return
        }
      }

      let fileFlag = false
      filesList.map(item => {
        if ('application/pdf.djvu'.indexOf(item.type) != -1 && filesList.length > 1) {
          fileFlag = true
        }
        formData.append('files', item)
      })

      // 单个Pdf或Djvu
      if (fileFlag) {
        this.$message.error('一本书仅支持上传单个Pdf或Djvu，请您分多本书上传。');
        this.loading = false;
        document.querySelector('#files').value = null
        return
      }

      axios({
        url: this.uploadURL,
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + getToken(),
        },
        data: formData
      })
      .then((response) =>{
        if (response.data.code == 200) {
          this.handleOcrList()
          if (response?.data?.data) {
            this.exceedFiveList = response.data.data.imgNameFailSizeList
            this.jamList = response.data.data.imgNameFailErrorList
            this.upFailVisible = true
            this.fileFailCount = response.data.data.fileFailCount
          } else {
            this.$message.success('上传成功');
          }
          
        } else {
          this.$message.error(response.data.msg || '上传有误!');
        }
        
        document.querySelector('#files').value = null
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        // document.querySelector('#files').value = null
        // this.$message.error(error?.data?.msg || '上传有误!');
      })
    },
    // 搜索
    handleSearch() {
      this.handleOcrList()
    },
    // 状态变更
    handleChangeStatus() {
      this.handleOcrList()
    },
    // 单个选中
    handleSelect(id) {
      this.bookList.map(item => {
        if (item.id == id && !item.checked) {
          item.checked = 1
        } else if(item.id == id && item.checked){
          item.checked = 0
        }
      })

      this.$forceUpdate()
    },
    // 全选
    handleAll() {
      this.bookList.map(item => {
        item.checked = 1
      })

      this.$forceUpdate()
    },
    // 取消全选
    handleCancleAll() {
      this.bookList.map(item => {
        item.checked = 0
      })
      this.$forceUpdate()
    },
    // 选择板式
    async handleOcrLayoutType() {
      const res = await metabookProgress({
        bookid: this.bookId,
        button: 0
      })

      // 判断是否有正在识别的任务
      if (res.data.progress) {
        return this.$message.error(res.data.progress);
      }
      this.layoutTypeFlag = true
    },
    // ocr识别开始
    async handleOcrStart() {
      this.countLoading = true
      let imgIdList = this.bookList.map(item => {
        if (item.checked){
          return item.id
        }
      }).filter(item => item)

      // 未选中全部识别
      if (!imgIdList.length) {
        imgIdList = this.bookList.map(item => {
          return item.id
        }).filter(item => item)
      }

      // 判断是否有额度
      const ret = await consumeCheck({
        ids: imgIdList.join(','),
        type: 'ocrImage',
        institutionId: this.userInfo.institutionId
      })

      this.countLoading = false

      this.remainingData = ret.data

      this.picNum = imgIdList.length

      if (this.picNum < 1) {
        return this.$message.warn('请先选择');
      }

      this.tipsStep = 'one'

      this.remainingFlag = true
      // 判断是否有额度
      // if (this.remainingData.needAvailableCount) {
      //   this.remainingFlag = true
      // } else {
      //   this.ocrVisible = true
      // }
    },
    // ocr识别弹层关闭
    async handleOcrClose() {
      this.ocrVisible = false
    },
    async handleOcrCallBack() {
      let imgIdList = this.bookList.map(item => {
        if (item.checked){
          return item.id
        }
      }).filter(item => item)

      // 未选中全部识别
      if (!imgIdList.length) {
        imgIdList = this.bookList.map(item => {
          return item.id
        }).filter(item => item)
      }

      if (this.tipsStep == 'one') { // ocr识别
        const res = await ocr({
          bookId: this.bookId,
          ids: imgIdList,
          isWait: 0,
          ocrType: this.layoutType
        })
        this.getSetTimeOcr(res.data.uuid)
        this.tipsStep = 'two'
        return
      } else { // 点击稍候查看
        try {
          this.loadingOcr = false
          this.ocrVisible = false
          clearInterval(this.timer)
          this.percentNum = 0
          this.$message.success('处理中,请到消息中心查看结果');
        } catch (error) {
          console.log('error', error)
        }
      }
      
    },
    // 循环查看进度
    async getSetTimeOcr(uuid) {
      this.timer = setInterval(async() => {
        const res = await getOcrProgress({
          bookId: this.bookId,
          uuid: uuid
        })
        this.percentNum = ((res.data.finishCount / res.data.total) * 100).toFixed(0)

        if (!res.data.isFinish) {
          clearInterval(this.timer)
          this.timerOut = setTimeout(() => {
            this.loadingOcr = false
            this.ocrVisible = false
            this.percentNum = 0
            this.$message.success('识别完成');
            this.handleOcrList()
            clearTimeout(this.timerOut)
          }, 1000)
        }
      }, 1000)
    },
    // 删除
    async handleDelete() {
      const imgIdList = this.bookList.map(item => {
        if (item.checked){
          return item.id
        }
      }).filter(item => item)
      try {
        if (imgIdList.length  == 0) {
          this.deleteVisible = false
          message.error('未找到相关图像信息')
          return
        }
  
        await deleteImgOcr({
          ids: imgIdList,
        })

        message.success('删除成功')
        this.handleDeleteClose()
        this.handleOcrList()
      } catch (error) {
        console.log('error', error)
      }
    },
    // 删除图书
    async handleDelBook() {
      try {
        const res = await deleteBook({
          ids: this.bookId,
        })

        message.success(res.data)
        this.$router.go(-1)
      } catch (error) {
        console.log('error', error)
      }
    },
    // 取消删除
    async handleDeleteClose(type) {
      if (type) {
        this.deleteType = type
      }

      const imgIdList = this.bookList.map(item => {
        if (item.checked){
          return item.id
        }
      }).filter(item => item)
      if (!this.deleteVisible && !imgIdList.length && this.deleteType == 'pic') {
        return message.warn('请先选择')
      } else if (this.deleteType == 'book'){
        // 获取该图书下有多少文本
        const res = await getDelMsg(this.bookId)

        this.picNum = res.data.imgCount
        this.textNum = res.data.textCount
      }
      this.deleteVisible = !this.deleteVisible
    },
    // 删除成功
    handleDeleteCallBack() {
      if (this.deleteType == 'pic') {
        this.handleDelete()
      } else if (this.deleteType == 'book'){
        this.handleDelBook()
      }
    },
    // 返回
    onBack() {
      this.$router.replace('/ocr')
    },
    /** 获取ocr图书列表 */
    async handleOcrList(data) {
      this.loading = true
      try {
        const res = await getImglist({
          sysBookId: this.bookId,
          fileName: this.inputValue,
          ocrStatus: this.ocrStatus
        })

        if (data) {
          data.map(item => {
            res.data.map(itm => {
              if (item.id == itm.id && item.checked) {
                itm.checked = true
              }
            })
          })
        }

        this.bookList = res.data || []
        this.loading = false
        
        this.previewerList = []
        this.bookList.map(item => {
          this.previewerList.push({
            msrc: `${this.imgPath}${item.filePath}`,
            src: `${this.imgPath}${item.filePath}`,
            w: 600,
            h: 400
          })
        })
      } catch (error) {
        this.$router.go(-1)
      }
    },
    // 图文对照
    async imgCompare(id, imgId, ocrStatus) {
      if (ocrStatus == 1) {
        await getOcrCheckList({
          sysBookId: this.bookId,
          id: id
        })
        this.$router.replace(`/ocr-compareImg?bookId=${this.bookId}&ocrStatus=${this.ocrStatusStep}&imgId=${imgId}&id=${id}&path=${JSON.stringify(this.$route.query)}`)
      }
    },
    // 提示文案显示
    handleTipsShow() {
      this.tipsVisible = true
    },
    // 图片超限提示
    tipsImageCallBack() {
      this.imgTipsVisible = false
    },
    // 上传失败提示
    closeUploadModal() {
      this.upFailVisible = false
    },
    // 额度不够提示 
    remainingImageCallBack() {
      this.remainingFlag = false
    },
    handleLayoutTypeClose() {
      this.layoutTypeFlag = false
    },
    handleLayoutTypeOk(value) {
      this.layoutType = value
      this.layoutTypeFlag = false
      this.handleOcrStart()
    }
  },
};
</script>

<style lang="less">
.pswp__zoom-wrap {
  transform: translate3d(0px, 0px, 0px) scale(1) !important;
  .pswp__img {
    width: auto !important;
    height: auto !important;
    max-width: 80% !important;
    max-height: 80% !important;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  }
}

.ocr-image-box {
  width: 100%;
  background: #edf0f2;
  padding: 46px 0px 100px;
  box-sizing: border-box;

  .left {
    display: flex;
    align-items: center;
    .tips {
      margin-left: 16px;
      color: #6868CE;
      cursor: pointer;
      i {
        margin-right: 6px;
      }
    }
  }

  .content {
    padding: 0px 51px;
    .title {
      display: flex;
      justify-content: space-between;
      .input {
        width: 190px;
        height: 32px;
        border: 1px solid #979797;
        position: relative;

        input {
          width: 100%;
          height: 100%;
          border: none;
          outline: none;
          padding: 3px 5px;

          :focus {
            outline: none;
          }
        }


        img {
          width: 19px;
          height: 19px;
          position: absolute;
          right: 13px;
          top: 6px;
          cursor: pointer;
        }
      }

      .status-box {
        margin-right: 70px;
        .ant-radio {
          margin-left: 24px;
        }
      }
    }

    .addBook {
      width: 113px;
      height: 34px;
      font-size: 14px;
      font-weight: 600;
      color: #FFFFFF;
      background: url(../../assets/icon-upload.png) no-repeat;
      background-size: cover;
      padding-left: 40px;
      line-height: 34px;
      cursor: pointer;
      position: relative;
      input {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: 0;
        cursor: pointer;
      }
    }

    .list {
      width: 100%;
      min-height: 500px;
      background: #ffffff;
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      border: 1px solid #e6e6e6;
      margin-top: 10px;
      padding: 0px 26px 16px 26px;
      box-sizing: border-box;

      .top {
        width: 100%;
        height: 59px;
        border-bottom: 1px dashed #c6c4c4;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .right {
          height: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .button-search {
            margin-left: 21px;
          }
          .sort-box {
            input {
              width: 50px;
              outline: none;
              border: 1px solid #ccc;
              padding: 2px 8px;
            }
          }  
        }
      }

      .bottom {
        width: 100%;
        min-height: 50px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-top: 20px;
        flex-wrap: wrap;

        .img-box {
          width: calc(16.66%);
          height: 250px;
          padding-top: 16px;
          padding-right: 23px;
          border-bottom: 1px dashed #C6C4C4;
          display: flex;
          flex-direction: row;
          .checked {
            width: 15px;
            height: 15px;
            border: 1px solid #6868CE;
            display: inline-block;
            border-radius: 50%;
            margin-right: 12px;
            vertical-align: top;
            cursor: pointer;
            &.active {
              background-color: #B4B4FF;
            }
          }

          

          .ocr-img-wrap {
            width: calc(100% - 27px);
            cursor: pointer;

            .ocr-img-box {
              padding: 6px;
              background: #EEF3FF;
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
              width: calc(100% - 12px);
              height: 157px;
              img {
                max-width: 100%;
                max-height: 145px;
                background: #EEF3FF;
              }
              span {
                min-width: 25px;
                height: 25px;
                background: #EEF3FF;
                position: absolute;
                left: 6px;
                top: 6px;
                font-size: 14px;
                font-weight: 500;
                color: #6868CE;
                line-height: 25px;
              }
            }
          }
          
          p {
            line-height: 28px;
            font-size: 14px;
            font-weight: 400;
            color: #4A4A4A;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: block;
          }
        }
      }
    }
  }

  .fileName-box {
    width: 100%;
    p {
      &:nth-of-type(1) {
        display: flex;
        align-items: center;
      }
    }
    img {
      width: 20px !important;
      height: 21px !important;
      margin-right: 10px;
    }
    &.error p{
      color: #FF5747 !important;
    }
  }

  .dragClass {
    border: 1px solid red !important;
  }
}

</style>
